import * as React from "react"
import "./header.css"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "./button"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { useState } from "react"

export const Header: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0)

  useScrollPosition(({ currPos }) => {
    setScrollPosition(currPos.y)
  })

  return (
    <>
      <header className="header" data-scroll={scrollPosition}>
        <div className="header__wrapper">
          <StaticImage
            placeholder="none"
            height={40}
            className="header__logo"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../images/logo-horiz.svg"
            alt="Madison Fencing Co – Vinyl Fence Installation & Repair"
          />
          <ul style={{ flexShrink: 0, whiteSpace: "nowrap" }}>
            <li>
              <a href="#services">Services</a>
            </li>
            <li>
              <a href="#fence-options">Fence Options</a>
            </li>
            <li>
              <a href="#quote">Get a quote</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
          </ul>
          <a className="start-link" href="#quote">
            <Button color="secondary">Start Now</Button>
          </a>
        </div>
      </header>
      <div className="fixed-header-adjust" />
    </>
  )
}
