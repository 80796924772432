import classNames from "classnames"
import * as React from "react"
import './button.css';

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  className?: string
  color?: "brand" | "secondary"
}

export const Button: React.FC<ButtonProps> = ({
  className,
  color = "brand",
  ...buttonProps
}) => {
  const computedClassNames = classNames("mf-button", className)

  return (
    <button
      className={computedClassNames}
      {...buttonProps}
      style={{ backgroundColor: `var(--colors-${color})` }}
    />
  )
}
